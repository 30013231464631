/* 
https://colordesigner.io/color-palette-builder?mode=lch#121232-C1E6F6-43BDE9-2F55B2-1E2C7E-E74D3C-2B3D50
// Tints
#121232
#292746
#413e5b
#5a5771
#737087
#8e8b9e
#a9a7b5
#c5c3cd
#e2e1e6
#ffffff
// Shades
#121232
#12102d
#110f28
#100d24
#0f0b1f
#0d091a
#0a0616
#060410
#030209
#000000
// Tones
#121232
#1e1d3a
#2a2843
#36334b
#423f54
#4e4c5c
#5a5865
#66656e
#737277
#808080
// Color Harmonies
// analogous
#121232
#221232
#321232
// triad
#121232
#321212
#123212
// complementary
#121232
#323212
// split complementary
#121232
#322212
#223212
// square
#121232
#321222
#323212
#123222
// rectangle
#121232
#321232
#323212
#123212
 */

/* Cool Energy  */
:root .themeTwo {
   /*** ACCENT ***/
   --iq-color-accent: #43bde9;
   --iq-color-accent-RGB: 67, 189, 233;
   --iq-color-accent-gradient: radial-gradient(
      ellipse at center,
      var(--iq-color-accent) 0%,
      rgba(0, 0, 0, 1) 100%
   );
   --iq-color-accent-light: #a5daf3;
   --iq-color-accent-dark: #2e657a;

   /*** ACCENT 2***/
   --iq-color-accent-2: #e74d3c;
   --iq-color-accent-2-gradient: radial-gradient(
      ellipse at center,
      var(--iq-color-accent-2) 0%,
      rgba(0, 0, 0, 1) 100%
   );
   --iq-color-accent-2-light: #fca18e;
   --iq-color-accent-2-dark: #fca18e;
   /*** BACKGROUND ***/
   --iq-color-background: #c1e6f6;
   --iq-color-background-warm-gradient: linear-gradient(
      180deg,
      var(--iq-color-background-warm) 5%,
      var(--iq-color-accent) 60%
   );
   --iq-color-background-warm: #f6d1c1;
   --iq-color-background-cool: #c1ccf6;
   --iq-color-background-contrast: #f6ebc1;
   /*** Foreground ***/
   --iq-color-foreground: #121232;
   --iq-color-foreground-gradient: linear-gradient(
      177deg,
      #43bde9 -17%,
      #121232 36%
   );
   --iq-color-foreground-radial-gradient: radial-gradient(
      var(--iq-color-accent) 20%,
      var(--iq-color-foreground) 80%
   );

   /*** Alert ***/
   --iq-color-alert-red: #e74c3c;
   --iq-color-alert-text: white;

   /*** TEXT ***/
   --iq--font-heading: small-caps normal 700 24px "ANDTIOH One", sans-serif;
   --iq--font-heading-2: small-caps normal 700 18px "ANDTIOH One", sans-serif;
   --iq--font-heading-3: small-caps normal 700 18px "Michroma", sans-serif;
   --apo-general-font-letter-spacing: 0.125em;
   --iq-spacing-heading: 0.25em;
   --iq-spacing-subheading: 0.375em;
   --iq-font-subheading: small-caps normal 700 12px "Michroma", sans-serif;
   --iq-font-body-text: normal 500 12px "Michroma", sans-serif;

   /*** SHADOWS ***/
   --apo-boxshadow-small-glow-inner: inset 0 0 15px 0px var(--iq-color-accent);
   --apo-boxshadow-outer: 0 -30px 50px -30px var(--iq-color-accent),
      0 30px 50px -30px var(--iq-color-accent);
   --apo-boxshadow-inner: inset 0 -30px 50px -30px var(--iq-color-accent),
      inset 0 30px 50px -30px var(--iq-color-accent);

   /*** BORDER ***/
   --apo-border-button-1: 1px solid var(--iq-color-accent);

   /*** SPECIAL USE ***/
   --apo-color-main-title-bkg-alt-1: var(--iq-color-foreground-gradient);
   --apo-color-main-title-color-alt-1: var(--iq-color-background);
   --apo-background-body: var(--iq-color-foreground);
   --apo-login-status-boxshadow: var(--apo-boxshadow-inner),
      var(--apo-boxshadow-outer);
   --apo-login-status-form-boxshadow: none;
   --iq-color-nav-buttons-background: var(--iq-color-background-warm-gradient);
   --apo-color-navbar-title-wrap: none;
   --apo-border-radius-navbar-title-wrap: 50px;
   --apo-box-shadow-navbar-title-wrap: none;
   --iq-color-nav-buttons-color: var(--iq-color-foreground);
   --iq-color-scrollbar-track: var(--iq-color-foreground-gradient);
   --iq-color-scrollbar-thumb-inner: var(--iq-color-accent-2-dark);
   --iq-color-scrollbar-thumb-outer: var(--iq-color-foreground);
   --iq-add-tool-background: black;
   --iq-add-tool-forms-background: var(--iq-color-foreground);
   --iq-add-tool-forms-color: var(--iq-color-background);
   --apo-add-tool-color: var(--iq-color-accent);
   --apo-form-gradient: inset 0 27px 20px -16px var(--iq-color-accent-dark);
   --apo-form-outer-shadow: inset 0px 18px 10px 4px #005570,
      0px -17px 10px -10px var(--iq-color-accent-dark);
   --apo-form-top-border: 3px solid var(--iq-color-accent-dark);
   /* --apo-background-image: url(https://www.transparenttextures.com/patterns/brushed-alum.png);
  --apo-background-image-body: url(https://www.transparenttextures.com/patterns/brushed-alum.png); */

   --apo-button-background-version-download: var(
      --iq-color-background-warm-gradient
   );
   --apo-button-text-version-download: var(--iq-color-foreground);
}

.themeTwo select {
   /* border: 1px solid var(--iq-color-accent); */
   border-radius: 50px;
   padding: 0.25em 0.25em 0.25em 1em;
   background: var(--iq-color-accent);
   color: var(--iq-color-background-cool);
   background: var(--iq-color-accent-gradient);
   text-shadow: 0 0 5px var(--iq-color-background-contrast);
   font-weight: 700;
   text-transform: uppercase;
   font-variant: all-small-caps;
   background: var(--iq-color-foreground);
   /* box-shadow: inset 3px 3px 15px -3px var(--iq-color-accent); */
}

/* 
background: var(--iq-color-foreground-gradient);
    color: inherit; */
/* COLOR GROUPS FOR BUTTONS */
:root .themeTwo {
   --color-group-one-white: #ffffff;
   --color-group-one-black: #1a1a1d;
   --color-group-one-1: #5680e9;
   --color-group-one-1-text: var(--color-group-one-white);
   --color-group-one-2: #ff700a;
   --color-group-one-2-text: var(--color-group-one-black);
   --color-group-one-3: #ff0a0a;
   --color-group-one-3-text: var(--color-group-one-black);
   --color-group-one-4: #c1c8e4;
   --color-group-one-4-text: var(--color-group-one-black);
   --color-group-one-5: #0aff99;
   --color-group-one-5-text: var(--color-group-one-black);
   --color-group-one-6: #84ceeb;
   --color-group-one-6-text: var(--color-group-one-black);
   --color-group-one-7: #5ab9ea;
   --color-group-one-7-text: var(--color-group-one-black);
   --color-group-one-8: #e3bd26;
   --color-group-one-8-text: var(--color-group-one-black);
   --color-group-one-9: #da2fc3;
   --color-group-one-9-text: var(--color-group-one-white);
   --color-group-one-10: #8860d0;
   --color-group-one-10-text: var(--color-group-one-white);
}

@keyframes textclip {
   to {
      background-position: 200% center;
   }
}

.themeTwo .animate {
   text-transform: uppercase;
   background-image: linear-gradient(
      -225deg,
      var(--iq-color-accent-dark) 0%,
      var(--iq-color-accent) 25%,
      vaR(--iq-color-accent-2) 50%,
      var(--iq-color-accent-dark) 100%
   );
   background-size: auto auto;
   background-clip: border-box;
   background-size: 100% auto;
   color: #fff;
   background-clip: text;
   text-fill-color: transparent;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation: textclip 2s linear infinite;
}

/* ////////////////////////// */
