/* 
https://colordesigner.io/color-palette-builder?mode=lch#FFFFFA-A1A194-5B605F-464646-FF6600
// Tints
// Tints
#fffffa
#fffffb
#fffffb
#fffffc
#fffffc
#fffffd
#fffffd
#fffffe
#fffffe
#ffffff
// Shades
#fffffa
#dfdfdb
#c0c0bd
#a2a29f
#858583
#696967
#4e4e4d
#353534
#1e1e1d
#000000
// Tones
#fffffa
#f0f0ec
#e1e1de
#d3d3d0
#c5c5c2
#b6b6b4
#a9a9a7
#9b9b9a
#8d8d8d
#808080
// Color Harmonies
// analogous
#fffffa
#fdfffa
#fafffa
// triad
#fffffa
#faffff
#fffaff
// complementary
#fffffa
#fafaff
// split complementary
#fffffa
#fafdff
#fdfaff
// square
#fffffa
#fafffd
#fafaff
#fffafd
// rectangle
#fffffa
#fafffa
#fafaff
#fffaff
 */

/* Cool Energy  */
:root .themeOne {
   /*** ACCENT ***/
   --iq-color-accent: #3498db;
   --iq-color-accent-RGB: 255, 135, 0;
   --iq-color-accent-gradient: radial-gradient(
      ellipse at center,
      var(--iq-color-accent) 0%,
      rgba(0, 0, 0, 1) 100%
   );
   --iq-color-accent-light: #a5daf3;
   --iq-color-accent-dark: #2e657a;

   /*** ACCENT 2***/
   --iq-color-accent-2: #e74c3c;
   --iq-color-accent-2-gradient: radial-gradient(
      ellipse at center,
      var(--iq-color-accent-2) 0%,
      rgba(0, 0, 0, 1) 100%
   );
   --iq-color-accent-2-light: #ffbe95;
   --iq-color-accent-2-dark: #ffbe95;

   /*** BACKGROUND ***/
   --iq-color-background: #292929;
   --iq-color-background-warm-gradient: linear-gradient(
      180deg,
      var(--iq-color-foreground) 0%,
      hsl(0, 0%, 36%) 50%
   );
   --iq-color-background-warm: #474040;
   --iq-color-background-cool: #42424a;
   --iq-color-background-contrast: #f6ebc1;

   /*** Foreground ***/
   --iq-color-foreground: #fffffa;
   --iq-color-foreground-gradient: linear-gradient(
      177deg,
      var(--iq-color-accent) 17%,
      var(--iq-color-foreground) 36%
   );
   --iq-color-foreground-radial-gradient: radial-gradient(
      var(--iq-color-accent) 20%,
      var(--iq-color-foreground) 80%
   );

   /*** Alert ***/
   --iq-color-alert-red: #e74c3c;
   --iq-color-alert-text: white;

   /*** TEXT ***/
   --iq--font-heading: small-caps normal 700 24px "ANDTIOH One", sans-serif;
   --iq--font-heading-2: small-caps normal 700 18px "ANDTIOH One", sans-serif;
   --iq--font-heading-3: small-caps normal 700 18px "Michroma", sans-serif;
   --apo-general-font-letter-spacing: 0.125em;
   --iq-spacing-heading: 0.25em;
   --iq-spacing-subheading: 0.375em;
   --iq-font-subheading: small-caps normal 700 12px "Michroma", sans-serif;
   --iq-font-body-text: normal 500 12px "Michroma", sans-serif;
   /*** SHADOWS ***/
   --apo-boxshadow-small-glow-inner: inset 0 0 15px 0px var(--iq-color-accent);
   --apo-boxshadow-outer: 0 -30px 50px -30px var(--iq-color-accent),
      0 30px 50px -30px var(--iq-color-accent);
   --apo-boxshadow-inner: inset 0 -30px 50px -30px var(--iq-color-accent),
      inset 0 30px 50px -30px var(--iq-color-accent);
   /*** BORDER ***/
   --apo-border-button-1: 1px solid var(--iq-color-accent);

   /*** SPECIAL USE ***/
   --apo-color-main-title-bkg-alt-1: var(--iq-color-foreground);
   --apo-color-main-title-color-alt-1: var(--iq-color-background);
   --apo-background-image: url(https://www.transparenttextures.com/patterns/brushed-alum.png);
   --apo-background-image-body: none;
   --apo-background-body: none;
   --apo-login-status-boxshadow: var(--apo-boxshadow-inner),
      var(--apo-boxshadow-outer);
   --apo-login-status-form-boxshadow: none;
   --apo-color-navbar-title-wrap: var(--iq-color-foreground);
   --apo-border-radius-navbar-title-wrap: 50px 25px;
   --apo-box-shadow-navbar-title-wrap: 1px 1px 3px -2px #000000 inset,
      -1px -1px 3px -2px #ffffff inset;
   --iq-color-nav-buttons-background: var(--iq-color-accent);
   --iq-color-nav-buttons-color: var(--iq-color-foreground);
   --iq-color-scrollbar-track: radial-gradient(
      var(--iq-color-accent),
      var(--iq-color-accent-light)
   );
   --iq-color-scrollbar-thumb-inner: var(--iq-color-accent);
   --iq-color-scrollbar-thumb-outer: var(--iq-color-accent-dark);
   --iq-add-tool-background: var(--iq-color-background);
   --iq-add-tool-forms-background: var(--iq-color-foreground);
   --iq-add-tool-forms-color: var(--iq-color-background);
   --apo-add-tool-color: var(--iq-color-accent);
   --apo-form-gradient: inset 0 7px 20px -16px rgba(128, 128, 128, 0.449);
   --apo-form-outer-shadow: none;
   --apo-form-top-border: 3px solid var(--iq-color-accent-dark);
   --apo-button-background-version-download: var(--iq-color-accent);
   --apo-button-text-version-download: var(--iq-color-foreground);
}

.themeOne input[value=""],
textarea {
   background: inherit;
   color: inherit;
}

.themeOne select {
   /* border: 1px solid var(--iq-color-accent); */
   border-radius: 50px;
   padding: 0.25em 0.25em 0.25em 1em;
   background: var(--iq-color-accent);
   color: var(--iq-color-background-cool);
   background: var(--iq-color-accent-gradient);
   text-shadow: 0 0 5px var(--iq-color-background-contrast);
   font-weight: 700;
   text-transform: uppercase;
   font-variant: all-small-caps;
   background: var(--iq-color-foreground);
   /* box-shadow: inset 3px 3px 15px -3px var(--iq-color-accent); */
}

/* 
background: var(--iq-color-foreground-gradient);
    color: inherit; */
/* COLOR GROUPS FOR BUTTONS */
:root .themeOne {
   --color-group-one-white: #ffffff;
   --color-group-one-black: #1a1a1d;
   --color-group-one-1: #5680e9;
   --color-group-one-1-text: var(--color-group-one-white);
   --color-group-one-2: #ff700a;
   --color-group-one-2-text: var(--color-group-one-black);
   --color-group-one-3: #ff0a0a;
   --color-group-one-3-text: var(--color-group-one-black);
   --color-group-one-4: #c1c8e4;
   --color-group-one-4-text: var(--color-group-one-black);
   --color-group-one-5: #0aff99;
   --color-group-one-5-text: var(--color-group-one-black);
   --color-group-one-6: #84ceeb;
   --color-group-one-6-text: var(--color-group-one-black);
   --color-group-one-7: #5ab9ea;
   --color-group-one-7-text: var(--color-group-one-black);
   --color-group-one-8: #e3bd26;
   --color-group-one-8-text: var(--color-group-one-black);
   --color-group-one-9: #da2fc3;
   --color-group-one-9-text: var(--color-group-one-white);
   --color-group-one-10: #8860d0;
   --color-group-one-10-text: var(--color-group-one-white);
}

@keyframes textclip {
   to {
      background-position: 200% center;
   }
}

.themeOne .animate {
   text-transform: uppercase;
   background-image: linear-gradient(
      -225deg,
      var(--iq-color-accent-2-dark) 20%,
      vaR(--iq-color-accent-2) 40%,
      var(--iq-color-accent) 50%,
      var(--iq-color-accent-dark) 80%
   );
   background-size: auto auto;
   background-clip: border-box;
   background-size: 100% auto;
   color: #fff;
   background-clip: text;
   text-fill-color: transparent;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation: textclip 2s linear infinite;
}

/* ////////////////////////// */
