.home-page {
   display: flex;
   max-width: 100%;
}

.column {
   position: relative;
   display: flex;
}

.column-two {
   flex-direction: column;
   position: relative;
   width: 100%;
   max-width: 100%;
   min-width: 0;
}
.column-two > div {
   position: relative;
   width: 100%;
   min-width: 0;
   max-width: 100%;
   margin: 0 0 0 auto;
   flex-grow: 1;
}

.column-two .bottom-navbar-wrap {
   position: sticky;
   bottom: 0;
   left: 0;
   z-index: 80;
}
@media only screen and (max-width: 768px) {
   .home-page {
      flex-direction: column;
      align-items: stretch;
   }
   .home-page > div {
      width: 100% !important;
      flex-basis: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
   }
   .home-page * {
      /* overflow: hidden; */
      /* display: none; */
      max-width: 100%;
      min-width: 0;
   }

   .show-on-small-screens {
      display: block;
   }
   .hide-on-small-screens {
      display: none;
   }
}

.new-version-alert {
   position: relative;
   /* border: 1px outset red; */
   border-radius: 50px;
   width: fit-content;
   height: fit-content;
   background: var(--iq-color-foreground-gradient);
   color: var(--iq-color-background);
   box-shadow:
      3px 3px 7px -4px white inset,
      -3px -3px 7px -4px black inset;
   padding: 2em 4em;
   margin: 1em auto;
   font-size: 1em;
}

.new-version-alert a {
   color: inherit;
   font-style: italic;
   margin: 0;
   transform: scale(1.125);
   transform-origin: center;
}

.update-version-text-container {
   text-align: left;
   padding: 0;
}
.update-version-text-container ul {
   text-align: left;
   padding: 0;
}

.new-version-alert-button-container {
   position: relative;
}
