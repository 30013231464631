.header-container {
   position: sticky;
   top: 0;
   width: 100%;
   text-align: center;
}

.fixed-header-container {
   /* background: linear-gradient(177deg, #5e5e5e 0%, #181818 67%); */
   /* background: var(--iq-color-foreground-gradient);
   background-image: var(--apo-background-image);
   color: var(--iq-color-accent);
   color: var(--iq-color-foreground); */
   background: var(--iq-color-foreground);
   color: var(--iq-color-background);
   overflow: hidden;
   z-index: 0;
   padding-bottom: 50px;
   margin-bottom: -50px;
}

.navbar-container {
   position: fixed;
   display: flex;
   align-items: normal;
   justify-content: space-between;
   background: var(--iq-color-foreground-gradient);
   color: var(--iq-color-background-warm);
   width: 100%;
   height: fit-content;
   z-index: 100;
   padding: calc(0.25em + 0.4vh) 2em;
   font: var(--iq-font-body-text);
   box-shadow:
      inset 4px 9px 20px -13px hsla(0, 0%, 100%, 0.5),
      inset 0px -6px 10px -7px hwb(0deg 100% 0% / 25%),
      3px 3px 10px -7px black;
   border-radius: 0 0 50px 50px;
}

.navbar-container > * {
   margin: auto;
}

.navbar-container h1 {
   font-size: calc(1rem + 0.25vw);
   text-align: center;
   vertical-align: middle;
   display: flex;
   align-items: center;
   margin: 0 auto;
}

.navbar-container .navbar-login-wrap {
   font-variant: small-caps;
   font-size: calc(0.8rem + 0.2vw);
}

.navbar-container .navbar-login-wrap input[type="email"],
.navbar-container .navbar-login-wrap input[type="password"] {
   font-variant: small-caps;
   padding: 0 0.5em;
}

.navbar-container .navbar-login-wrap {
   letter-spacing: 0.25em;
}

.fixed-header-container h1 {
   line-height: 2em;
   width: 100%;
   font-size: calc(0.8em + 1vw);
   font-weight: 900;
   transform: scale(1.5, 1);
   z-index: 0;
   padding: 1em;
}

@keyframes triggerElmMeasure {
   0% {
      width: 100.01%;
   }

   100% {
      width: 100%;
   }
}
@keyframes moveDown {
   0% {
      transform: translateY(-100%);
   }

   100% {
      transform: translateY(0);
   }
}
@keyframes moveUp {
   from {
      transform: translateY(0);
   }
   to {
      transform: translateY(-100%);
   }
}

.hide-navbar {
   animation: moveUp 0.3s ease forwards;
}
.show-navbar {
   animation:
      moveDown 0.2s ease forwards,
      triggerElmMeasure 0.1s linear;
}

.login-slide-panel {
   position: fixed;
   top: 50px;
   left: 0;
   max-width: 100%;
   max-height: calc(100vh - 50px);
   min-height: 450px;
   min-width: 100%;
   background: transparent;
   height: fit-content;
   z-index: 10;
   transition: all 0.2s ease;
   padding: 1em;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: column;
   width: fit-content;
   transition: 0.3s all ease;
   margin: auto;
}
.login-slide-panel:before {
   content: "";
   position: fixed;
   display: flex;
   top: 0;
   left: 0;
   width: 100vh;
   height: 100vh;
   min-height: 100%;
   min-width: 100%;
   opacity: 0.9;
   background: var(--iq-color-foreground);
}
.login-slide-panel > div {
   max-width: 800px;
   margin: auto;
   background: var(--iq-color-foreground-gradient);
   z-index: 1;
   height: 100%;
   max-height: 100%;
   padding-bottom: 0;
   margin-bottom: -1em;
}

.login-slide-panel > div button {
   transform: none;
}

.login-slide-panel .login-slide-panel-close-wrap {
   margin: 0 auto;
   height: 25px;
   background: transparent;
   color: var(--iq-color-accent-light);
   box-shadow: none;
   min-width: min-content;
   /* flex-basis: 100%; */
}

.login-slide-panel .login-slide-panel-close-wrap button {
   margin: 2em auto;
   background: var(--iq-color-foreground);
   color: var(--iq-color-accent-light);
   /* box-shadow: none; */
   min-width: 18em;
   flex-basis: 100%;
   width: 50%;
}

/* Hover turned Off for touch and stylus */
body[class*="can-hover"]
   .login-slide-panel
   .login-slide-panel-close-wrap
   button:hover {
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
}

.login-button-wrap {
   min-width: fit-content;
}

.login-button-wrap.hide-on-small-screens {
   flex-grow: 1;
   min-width: fit-content;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50px;
   overflow: hidden;
   margin: auto 1em;
}

body .login-button-wrap.hide-on-small-screens button {
   flex-grow: 1;
   margin: auto !important;
   transform: none !important;
   padding: 0 0.75em 0.25em;
   height: 100%;
   width: auto;
   vertical-align: middle;
   border-radius: 0;
   background: var(--iq-color-nav-buttons-background) !important;
   color: var(--iq-color-nav-buttons-color) !important;
}

.show-on-small-screens {
   display: none;
}

.theme-selector-wrap {
   position: relative;
   margin: auto 0 auto 1em;
}

.theme-selector-wrap select {
   border: none;
   box-shadow: none;
}

/* SVG Logo */
.logo-wrap {
   max-width: 100%;
   margin: 0 0.25em 0 0;
}

.logo-wrap * {
   max-width: max-content;
   max-height: 100%;
   height: 2em;
   margin-bottom: -0.14em;
   transform: translateY(25%) scale(1, 1.5);
}

/* Electron handled transform slightly differently */
/* div[class*='desktop-version'] .logo-wrap * {
  transform: translateY(25%) scale(1, 1);
} */

.navbar-container .logo-wrap * {
   transform: none;
}

.navbar-container .logo-wrap {
   max-width: 100%;
   margin: 0 1em 0 0;
}

.navbar-container .logo-wrap * {
   max-width: max-content;
   max-height: 2em;
   height: 100%;
   /* margin-bottom: -0.25em;*/
}

.navbar-container .section-title-wrap {
   margin: auto 0;
   padding: 0;
}

.navbar-container .section-title-wrap {
   background: var(--apo-color-navbar-title-wrap);
   border-radius: var(--apo-border-radius-navbar-title-wrap);
   box-shadow: var(--apo-box-shadow-navbar-title-wrap);
}

span.logo-wrap path:nth-last-child(2),
span.logo-wrap path:last-child {
   fill: #c3c3c3 !important;
}
span.logo-wrap path:nth-last-child(2),
span.logo-wrap path:last-child {
   fill: #c3c3c3 !important;
}

.navbar-container a {
   display: flex;
   text-decoration: none;
   color: inherit;
   transition: 0.3s all ease;
   /* Accommodate theme selector elm */
   padding-left: 25px;
}

.navbar-container button[data-data="login-or-signup-button"] {
   /* margin-right: 8em; */
   padding: 0 2em 0.5em;
   min-width: 14em;
}

/* Hover turned Off for touch and stylus */
body[class*="can-hover"] .navbar-container h1:hover,
body[class*="can-hover"] .navbar-container h1:hover path:last-child,
body[class*="can-hover"] .navbar-container h1:hover path:nth-last-child(2) {
   fill: var(--iq-color-accent) !important;
   color: var(--iq-color-accent);
}

@keyframes pulseText {
   0% {
      color: initial;
   }
   50% {
      color: rgba(var(--iq-color-accent-RGB), 75%);
      text-shadow:
         1px 1px 0px hsl(0deg 0% 100% / 50%),
         -1px -1px 0px rgb(92 92 92 / 50%);
   }
   70% {
      color: initial;
   }
   0% {
      color: initial;
   }
}

.header-container h1 {
   animation:
      pulseText 3s ease,
      pulseText 18s ease infinite;
}
/* ----- */

.button-outer-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: auto 0;
}

@media only screen and (max-width: 1023px) {
   .fixed-header-container h1 {
      transform: scale(1.25, 1);
   }

   .logo-wrap * {
      transform: translateY(25%) scale(1, 1.25);
   }
}

@media only screen and (max-width: 768px) {
   .fixed-header-container h1 {
      transform: scale(1, 1);
   }
   .logo-wrap * {
      transform: translateY(25%) scale(1, 1);
   }
   .navbar-container {
      padding: 1em 0.5em;
      border-radius: 0;
   }
   .show-on-small-screens {
      display: block;
   }
   .hide-on-small-screens {
      display: none !important;
   }
   .navbar-container .logo-wrap * {
      max-height: 4em;
   }
}

@media only screen and (max-width: 600px) {
   .theme-selector-wrap {
      display: none;
   }
}
