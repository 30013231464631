.registration-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.registration-container h3 {
   padding: 0;
   margin-bottom: 0;
}

.form {
   display: flex;
   position: relative;
   flex-wrap: wrap;
}

.form input[type="text"],
.form input[type="password"],
.form input[type="email"] {
   font-size: 1em;
   padding: 0 0.5em;
   box-shadow:
      inset 4px 4px 4px -3px black,
      inset -4px -4px 4px -3px lightgrey;
}

.form label {
   margin: 0;
   line-height: 1em;
   padding: 0;
   display: inline-flex;
   justify-content: center;
   align-items: center;
}

.form .form-submit-button-wrap {
   display: flex;
   align-items: end;
   flex-grow: 1;
   min-width: 100%;
}

.form-submit-button-wrap button {
   margin: 0 auto 10px;
}
.registration-in-link {
   margin-left: 1em;
}

.registration-question {
   margin-top: 1em;
}
.registration-question button {
   margin-left: 1em;
   width: auto;
}

.form-input-container {
   display: flex;
   flex-grow: 1;
}

.form-input-container input {
   height: 3em;
}

.form-input-error {
   position: relative;
   padding: 0.5em 2em;
   max-width: fit-content;
   border: 4px inset var(--iq-color-accent);
   box-shadow:
      3px 3px 10px -7px inset black,
      -3px -3px 10px -7px inset white;
   border-radius: 10px;
   margin: 0 auto;
}

.form-input-error .form-input-error-close-button {
   position: absolute;
   top: 0.25em;
   left: 0.25em;
   font-weight: 700;
   margin: 0 auto;
   width: 1em;
   padding: 0;
   background: none;
   color: inherit;
   border: none;
   box-shadow: none;
   text-shadow: 0 0 3px var(--iq-color-accent);
}

.horizontal-display .form-input-error .form-input-error-close-button {
   position: absolute;
   left: 0;
   font-size: 18px;
   font-weight: 700;
}

.inner-form-input-container {
   position: relative;
   display: flex;
   max-height: 100%;
   height: fit-content;
   margin-top: 1rem;
   margin-bottom: 1rem;
   display: flex;
   justify-content: left;
   padding: 1em;
   flex-basis: fit-content;
   height: 100%;
   max-width: 100%;
   flex-wrap: wrap;
   width: min-content;
   width: min-content;
   min-width: min-content;
}

.captcha-container {
   min-width: 100%;
}

.captcha-wrap {
   min-width: 100%;
   margin: 0 auto;
}

.horizontal-display .captcha-wrap {
   position: relative;
   display: flex;
   max-height: 100%;
   height: fit-content;
}

.captcha-wrap > div {
   position: relative;
   display: flex;
   align-items: stretch;
   justify-content: center;
   min-width: 100%;
   height: fit-content;
   flex-wrap: wrap;
}

.captcha-wrap > label {
   flex-basis: 100%;
   font-variant: small-caps;
   margin: 0 auto 0.5em;
   font-size: 1.6rem;
   letter-spacing: var(--iq-spacing-heading);
}

.captcha-wrap > div > div:first-child {
   z-index: 1;
   border: 4px double var(--iq-color-accent);
}

.captcha-wrap div button,
.captcha-wrap input {
   height: 100% !important;
   /* min-height: 100% !important; */
   margin: auto !important;
   display: flex !important;
   height: auto !important;
   max-height: none !important;
   min-height: 4em;
}
.captcha-wrap div button {
   max-width: max-content !important;
}
.captcha-wrap input {
   flex-grow: 1;
}
.horizontal-display .form-input-error {
   position: absolute;
   top: 100%;
   padding: 0.5em 2em 1em !important;
   background: var(--iq-color-foreground-gradient);
   color: var(--iq-color-background-warm);
   left: 50%;
   border-radius: 0 0 25px 25px;

   transform: translateX(-50%);
   max-width: 800px;
}

.horizontal-display .form .form-submit-button-wrap {
   min-width: 0;
}
