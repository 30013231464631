.csv-reader {
   display: flex;
   flex-direction: column;
   margin-bottom: 10;
}
.browse-file {
   width: "20%";
}
.accepted-file {
   /* border: 1px solid #ccc; */
   height: 45;
   line-height: 2.5;
   position: absolute;
   top: 95%;
   left: 0;
   display: block;
   width: calc(100% - 10em);
   background: var(--iq-color-accent-dark);
   color: var(--iq-color-accent-light);
   border-radius: 50px 50px 0 0;
   margin: auto 5em;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   padding: 0 1em;
}

.remove {
   border-radius: 0;
   padding: 0 20px;
}

@keyframes rotateDisc {
   0% {
      transform: rotate(0deg), scale(0);
   }
   50% {
      transform: rotate(180deg), scale(2);
   }

   100% {
      transform: rotate(0deg), scale(0);
   }
}
/* .progress-bar-background-color {
  position: fixed;
  top: 50%;
  left: 48%;
  padding: 2em;
  width: 2em !important;
  background-image: linear-gradient(
    90deg,
    var(--iq-color-foreground),
    var(--iq-color-accent)
  );
  border-radius: 50% !important;
  animation: rotateDisc 3s ease infinite 0.3s;
} */

/* App Customizations */
.csv-reader {
   flex-direction: row;
   min-width: 100%;
   min-height: 100%;
}

.csv-reader button {
   margin: 0;
   min-width: 100%;
   min-height: 100%;

   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
   border: none;
   box-shadow:
      inset -3px -3px 7px -4px black,
      inset 3px 3px 7px -4px white;
   border-radius: 50px;
   font: var(--iq-font-body-text);
   font-size: 1em;
   font-weight: 700;
}
