.outerwrap {
   position: relative;
}

.main-title {
   font: var(--iq--font-heading-2);
   letter-spacing: var(--iq-spacing-heading);
   position: sticky;
   display: block;
   top: -1em;
   padding: 0.5em 0.5em;
   /* min-height: calc(30px + 5em); */
   /* max-height: none; */
   border-radius: 50px;
   z-index: 1;
   background: var(--apo-color-main-title-bkg-alt-1);
   color: var(--apo-color-main-title-color-alt-1);
   /* color: #00000063; */
   margin: 0;
   width: 176px;
   z-index: 11;
   box-shadow:
      3px 3px 7px -7px #000000e8,
      7px 7px 10px -7px rgb(0 0 0 / 47%);
   box-shadow: var(--apo-boxshadow-outer), var(--apo-boxshadow-outer);
   font-weight: 900;
}

.slide-button-wrap {
   display: flex;
   flex-direction: column;
   max-width: 16em;
   padding-top: 10px;
   align-items: center;
   display: grid;
   grid-template-areas:
      "favorite"
      "rating"
      "status"
      "functions"
      "color"
      "precision"
      "company"
      "oversampling";
   grid-template-columns: repeat(1, 1fr);
   grid-auto-rows: minmax(auto, auto);
}

.outerwrap [data-topic="favorite"] {
   grid-area: favorite;
}

.outerwrap [data-topic="favorite"] input,
.outerwrap [data-topic="oversampling"] input {
   /* transform: translateY(12.5%); */
}

.outerwrap [data-topic="favorite"] h3,
.outerwrap [data-topic="rating"] h3,
.outerwrap [data-topic="oversampling"] h3 {
   transform: translateY(-17%);
   opacity: 1;
}

.outerwrap [data-topic="color"] {
   grid-area: color;
}
.outerwrap [data-topic="precision"] {
   grid-area: precision;
}
.outerwrap [data-topic="oversampling"] {
   grid-area: oversampling;
}
.outerwrap [data-topic="rating"] {
   grid-area: rating;
}
.outerwrap [data-topic="status"] {
   grid-area: status;
}

.slide-button-wrap .topic-group {
   overflow: visible;
}

.slide-button-wrap .topic-group button {
   position: relative;
   transform-origin: top;
   transition: 0.2s all ease !important;
}

.slide-button-wrap .topic-group button:hover {
   transform: scale(1, 1.25);
   transform-origin: top;
}

.slide-button-inner-wrap {
   border: 1px solid var(--iq-color-accent-dark);
   border-radius: 12.5px;
}

.slide-button-inner-wrap {
   display: flex;
   flex-wrap: wrap;
   width: -moz-fit-content;
   width: 100%;
   max-width: 100%;
   overflow: hidden;
   flex-wrap: wrap;
   justify-content: start;
   flex-grow: 1;
   align-items: center;
   flex-basis: 50%;
   /* flex-direction: column; */
   padding: 0.5em 0;
}

.slide-button-wrap > * {
   flex-basis: 50%;
   min-width: 100%;
   margin: 1em auto;
   width: 50%;
   overflow: hidden;
   white-space: break-spaces;
   word-break: normal;
}

.slide-button-inner-wrap:first-child {
}
.slide-button-inner-wrap:last-child {
}
/* .slide-button-wrap:first-child {
  min-width: 100%;
}

.slide-button-wrap:nth-child(2),
.slide-button-wrap:nth-child(3) {
  flex-basis: auto;
} */

.slide-button-inner-wrap-title {
   min-width: 100%;
   font: var(--iq-font-subheading);
   letter-spacing: var(--iq-spacing-subheading);
   opacity: 0.5;
}

.output-container {
   font: var(--iq-font-subheading);
   font-weight: 700;
   letter-spacing: 0.125em;
}

.favorite-slide-button-inner-wrap,
.rating-slide-button-inner-wrap,
.oversampling-slide-button-inner-wrap {
   display: flex;
   justify-content: right;
   padding-right: 1em;
   position: relative;
   overflow: visible;
}

.oversampling-slide-button-inner-wrap form input:nth-child(2),
.oversampling-slide-button-inner-wrap label {
   display: none;
}

.favorite-slide-button-inner-wrap .slide-button-inner-wrap-title,
.rating-slide-button-inner-wrap .slide-button-inner-wrap-title,
.oversampling-slide-button-inner-wrap .slide-button-inner-wrap-title {
   min-width: min-content;
}

.rating-slide-button-inner-wrap select {
   background: inherit;
   color: inherit;
   padding: 0 0.5em;
   margin-left: 0.5em;
   border-color: var(--iq-color-accent-dark);
   box-shadow: none;
}

.rating-slide-button-inner-wrap .option-selected {
   display: flex;
   position: absolute;
   /* border: 3px solid var(--iq-color-accent); */
   width: 2.8em;
   height: 1.7em;
   right: 0;
   top: 50%;
   transform: translate(4%, -50%);
   pointer-events: none;
}
/* *** Checkbox Styles *** */
.slide-button-inner-wrap input[type="checkbox"] + label {
   flex-grow: 1;
   padding: 0.5em;
   font-size: 18px;
   font-weight: 700;
   border: none;
}
.slide-button-inner-wrap input[type="checkbox"] {
   -webkit-appearance: none;
   background-color: #12123200;
   border: 1px solid var(--iq-color-accent);
   opacity: 0.5;
   box-shadow: none;
   padding: 9px;
   border-radius: 10px;
   display: inline-block;
   position: relative;
   margin-left: 0.5em;
}
.slide-button-inner-wrap input[type="checkbox"]:active,
.slide-button-inner-wrap input[type="checkbox"]:checked:active {
   box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.slide-button-inner-wrap input[type="checkbox"]:checked {
   background-color: transparent;
   border: none;
   box-shadow: none;
   color: transparent;
   opacity: 1;
}

.slide-button-inner-wrap input[type="checkbox"]:checked:after {
   content: "\2714";
   font-size: 28px;
   position: absolute;
   font-weight: 900;
   top: 12.5%;
   left: 0px;
   max-width: 110%;
   min-width: 110%;
   min-height: 110%;
   color: var(--iq-color-accent-2);
   /* background: var(--iq-color-foreground); */
   box-shadow: none;
   transform: scale(1.5) translate(12%, -32%);
   text-shadow: 0 0 5px var(--iq-color-foreground);
}
/****************/

@media only screen and (max-width: 768px) {
   .slide-button-wrap {
      margin: 0 auto;
   }
}

@media only screen and (max-width: 600px) {
   .main-title {
      width: 100%;
      top: 30px;
      position: relative;
   }
}
