.output-controls {
   justify-content: center;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.output-controls button {
   padding: 0.5em 2.5em 0.75em;
   min-width: 15em;
}
