.footer-column a {
   letter-spacing: 0.1em;
   text-transform: uppercase;
   font-weight: 700;
   text-decoration: none;
   display: inline-block;
   transform-origin: left;
   transition: all 0.2s ease;
}

.new-version-alert {
   border: 1px outset red;
   border-radius: 50px;
   background: var(--iq-color-alert-red);
   color: var(--iq-color-alert-text);
   box-shadow:
      3px 3px 7px -4px white inset,
      -3px -3px 7px -4px black inset;
}

.new-version-alert a {
   color: inherit;
   font-style: italic;
   margin: 0;
   transform: scale(1.125);
   transform-origin: center;
}

/* Hover turned Off for touch and stylus */
body[class*="can-hover"] .footer-column a:hover {
   text-shadow: 0 0 3px var(--iq-color-accent);
   transform: scale(1, 1.25);
}

@media screen and (max-width: 600px) {
   .footer-container {
      font-size: 1rem;
   }
}
