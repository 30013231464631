.edit-buttons-wrap {
   position: relative;
   display: block;
   border: 0;
   margin: 0 auto;
   max-width: 80%;
   z-index: 11;
   padding-right: 34px;
   min-height: max-content;
   height: 107px;
   background: transparent;
   padding-top: 27px;
   box-shadow: 0px 40px 31px -46px #000000;
}

.inner-wrap {
   position: relative;
   height: 100%;
}

.inner-wrap input[type="submit"]:hover {
   opacity: 1 !important;
}

.error-text {
   border-bottom: 14px inset orangered !important;
   border-top: 14px inset orangered !important;
   color: orangered !important;
   font-size: 1.5em;
   margin: 1em auto 0;
}
