/******************************
*
*       Files to Load
*
*******************************/

@font-face {
   font-family: "Good Times Rg";
   src:
      url("./assets/fonts/good_times/good_times_rg1-webfont.woff2")
         format("woff2"),
      url("./assets/fonts/good_times/good_times_rg1-webfont.woff")
         format("woff");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: "Michroma";
   src:
      url("./assets/fonts/michroma/michroma-regular-webfont.woff2")
         format("woff2"),
      url("./assets/fonts/michroma/michroma-regular-webfont.woff")
         format("woff");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: "ANDTIOH One";
   src:
      url("./assets/fonts/andtioh/ANDTIOHOneRegular.woff2") format("woff2"),
      url("./assets/fonts/andtioh/ANDTIOHOneRegular.woff") format("woff");
   font-weight: normal;
   font-style: normal;
}

@keyframes fadein {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@keyframes scale {
   0% {
      transform: scale(0);
   }
   50% {
      transform: scale(0);
   }
   100% {
      transform: scale(1);
   }
}
/* defaults */
html {
   font-size: 10px;
   background: var(--iq-color-foreground);
   color: var(--iq-color-background-warm);
}
* {
   box-sizing: border-box;
}
body {
   /* Defaults */
   background-color: #ffffff;
   color: #003647;
   font:
      normal 500 12px "Kodchasan",
      sans-serif;
   margin: 0;
   /* Theme Settings */
   font: var(--iq-font-body-text);
   letter-spacing: var(--apo-general-font-letter-spacing);
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background: var(--iq-color-foreground-gradient);
   background: linear-gradient(177deg, #5e5e5e 0%, #121232 1%);
   background: var(--apo-background-body);
   color: var(--iq-color-background-warm);
   min-height: 100vh;
   max-height: 100vh;
   overflow: auto;
   align-items: flex-start;
   /* animation:
    fadein 7s cubic-bezier(1, -0.16, 0.41, 1),
    scale 3s cubic-bezier(0.97, -0.11, 0.85, 1.38); */
   animation: fadein 3s cubic-bezier(1, -0.16, 0.41, 1);
   overscroll-behavior: none;
}

a {
   color: var(--iq-color-accent);
}

code {
   /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
   white-space: pre-wrap;
}

.section-title {
   font: var(--iq--font-heading-2);
   letter-spacing: var(--iq-spacing-heading);
}
.section-subtitle {
   font: var(--iq-font-subheading);
   letter-spacing: var(--iq-spacing-heading);
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b,
i,
strong {
   text-shadow:
      1px 1px 0px hsl(0deg 0% 100% / 25%),
      -1px -1px 0px rgba(92, 92, 92, 0.207);
}

/* Add a Tool Popup */
.popup {
   z-index: 10;
   position: relative;
}

.popup:before {
   content: "";
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: #000000;
   opacity: 0.75;
   z-index: 0;
}

.popup > * {
   position: relative;
   border: 3px solid var(--iq-color-accent-2);
   box-shadow:
      0 0 20px 0 var(--iq-color-accent-2),
      inset 0 0 20px 0 var(--iq-color-accent-2);
   border-radius: 50px;
   padding: 1em;
   color: var(--iq-color-background-contrast);
}
