.db-login-container {
  box-shadow:
    0 -30px 50px -50px white,
    0 30px 50px -50px white;
  padding: 1em;
  margin: 2em;
  border-radius: 50px;
  box-shadow: var(--apo-login-status-boxshadow);
}

.db-login-container h4 {
  margin: 0 auto 0.5em;
  padding: 0;
  font: var(--iq-font-subheading);
}

.db-login-container,
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 0 1em;
}

.button-container > * {
  max-width: 25rem;
  width: 100%;
  flex-grow: 1;
}

.button-container button,
.button-container span {
  font-size: 0.9em;
}

.db-login-container p {
  margin: 0 auto 1em;
  padding: 0;
}

.db-login-container button {
  margin-top: 1em;
}
.db-login-status {
  margin: 1em;
}

.db-login-status {
  margin: auto;
}

.login-text {
  display: inline-block;
  margin: 0 auto;
  padding: 0.25em 2em;
  font-weight: 700;
  width: 100%;
  box-shadow:
    inset 1px 1px 3px -2px black,
    inset -1px -1px 3px -2px white;
  border: 1px inset var(--iq-color-background-warm);
  background: var(--iq-color-background-warm);
  color: var(--iq-color-foreground);
  border-radius: 50px;
}

.not-logged-in-text {
  background-color: var(--iq-color-foreground);
  color: var(--iq-color-background);
  font-size: 1.25em;
  font-weight: 700;
  border: 2px inset var(--iq-color-accent);
  padding: 0.5em 1em;
  margin-top: 1em;
}

.form-container {
  box-shadow:
    3px 3px 10px -7px inset black,
    -3px -3px 10px -7px inset white;
  box-shadow: var(--apo-login-status-form-boxshadow);
  width: 100%;
  max-width: none;
  border-radius: 10px;
  margin: 0px auto;
}

body .form-container label {
  font-size: 1em;
}

body .form-container input[type='text'],
body .form-container input[type='email'],
body .form-container input[type='password'] {
  font-size: 1em;
  padding: 0px 2em;
  margin: 0;
  height: 2em;
}

.button-container button {
  margin: 0.25em auto 0.4em;
  padding: 0.5em 2em;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background: var(--iq-color-background-warm-gradient);
  color: var(--iq-color-foreground);
  box-shadow:
    inset 1px 1px 1px rgb(255 255 255 / 25%),
    inset 1px -1px 1px rgb(0 0 0 / 15%),
    1px 1px 5px -3px black;
  border: none;
  font: var(--iq-font-body-text);
  font-size: 1em;
  font-weight: 700;
}

/* HORIZONTAL DISPLAY */
.db-login-container.horizontal-display {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0;
  max-width: max-content;
  font: inherit;
  max-height: 100%;
  height: fit-content;
}
.db-login-container.horizontal-display * {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0;
  max-width: max-content;
  font: inherit;
  /* height: 30px; */
  min-height: fit-content;
  max-width: 100%;
}

.db-login-container.horizontal-display form input,
.db-login-container.horizontal-display form button {
  min-height: 30px;
  height: 100%;
  max-height: none;
}

.db-login-container.horizontal-display span {
  display: flex;
  align-items: center;
}

.db-login-container.horizontal-display span * {
  min-height: 30px;
}

.db-login-container.horizontal-display span button {
  margin-left: 1em;
  color: var(--iq-color-foreground);
  text-shadow: 0 0 3px wheat;
  background: linear-gradient(
    var(--iq-color-accent) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  max-height: min-content;
  min-height: max-content;
  margin: auto;
}
.db-login-container.horizontal-display label {
  display: none;
}
.db-login-container.horizontal-display input::placeholder {
  color: #0000005e;
  opacity: 1;
  font-weight: 700;
}
.db-login-container.horizontal-display input {
  border: 1px inset #c8c8c8;
}
.db-login-container.horizontal-display button {
  margin: 0 1em 0 0;
  padding: 0 1em;
}

.db-login-container.horizontal-display p {
  margin: 0;
}
.db-login-container.horizontal-display .not-logged-in-text,
.db-login-container.horizontal-display h4,
.db-login-container.horizontal-display h3 {
  display: none;
}

.db-login-container.horizontal-display .button-container,
.db-login-container.horizontal-display .form-container {
  margin: 0 auto;
  padding: 0;
  box-shadow: none;
}

.db-login-container.horizontal-display .not-logged-in-text {
  display: none;
}

.db-login-container.horizontal-display .login-question {
  margin-right: 3em;
}

.db-login-container.horizontal-display .login-text {
  padding: 0 1em;
  background: none;
  color: inherit;
  border: none;
  box-shadow: none;
  align-items: center;
  max-width: max-content;
  min-width: 10em;
}
