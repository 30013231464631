.error-container {
  padding: 0 3em;
}

.error-text-container {
  max-width: 800px;
}

.error-text-container p {
  white-space: break-spaces;
}
