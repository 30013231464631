.plugin-finder-container {
   position: relative;
   /* top: 0; */
   /* left: 0; */
   width: 100%;
   max-height: 100%;
   margin: 3em auto 0;
   /* z-index: 200; */
   /* background: #000000f7; */
   /* overflow: auto; */
   /* padding: 2em; */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.plugin-finder-selector-wrap.open {
   padding: 1em;
   /* border: 1px solid; */
   border-radius: 50px;
   background: var(--iq-color-foreground-radial-gradient);
   margin: 1em auto;
   box-shadow:
      0 0 10px 0px var(--iq-color-accent-light),
      0 0 40px 0 var(--iq-color-accent-light);
   margin-bottom: 3em;
}
@keyframes pulse {
   0% {
      transform: scale(1);
   }
   5% {
      transform: scale(1, 1.25);
   }
   10% {
      transform: scale(1, 1.5);
   }
   15% {
      transform: scale(1);
   }
   20% {
      transform: scale(1, 1.25);
   }
   25% {
      transform: scale(1);
   }
   30% {
      transform: scale(1, 1.5);
   }
   50% {
      transform: scale(1);
   }
}

.pulse {
   animation: pulse 5s ease 1s infinite;
}

.button-container,
.url-input-container {
   display: flex;
   width: 100%;
   grid-column: 1 / -1;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}

.url-input-container {
   background: var(--iq-color-foreground);
   color: var(--iq-color-background);
   border: 1px solid;
   padding: 1em 2em 2em;
   border-radius: 50px;
}

.url-input-container > * {
   flex-basis: 100%;
}

.url-input-container input {
   font-weight: 500;
}
.url-input-container button {
   width: 50%;
   max-width: 80%;
   flex-basis: 100%;
   margin: 1em auto;
}

.location-input-container {
   display: flex;
   flex-direction: column;
}
.button-container > * {
   margin: 0 1em;
}

.plugin-finder-container button,
.button-container label {
   position: relative;
   font-weight: 700;
   box-shadow:
      3px 3px 7px -4px #ffffffdb inset,
      -3px -3px 7px -4px black inset;
   padding: 0.5em 5em;
   border-radius: 50px;
   transition: 0.1s all ease;
   /* transform: scale(1, 1.125) translateX(-1em); */
   background: var(--iq-color-background);
   color: var(--iq-color-foreground);
   border: none;
   font-variant: small-caps;
   font-weight: 700;
}

.plugin-finder-container [class*="close-item-form-button"] {
   background: var(--iq-color-accent-2);
   color: var(--iq-color-foreground);
}

.plugin-finder-container .ignore-list-button {
   background: transparent;
   color: #ff8700;
   box-shadow:
      1px 1px 7px -4px #ffffffdb inset,
      -1px -1px 4px -2px black inset;
   font-size: 9px;
   margin: 0 auto 0 5px;
   padding: 0 5px;
   opacity: 0.7;
   transform: scale(1);
   width: fit-content;
   transition: 0.3s all ease;
}

.plugin-finder-container .find-new-plugins-button {
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
}

.plugin-finder-container .find-new-plugins-button.open {
   background: var(--iq-color-foreground);
   color: var(--iq-color-accent);
   min-height: 2em;
}

.plugin-finder-container button.button-action-needed {
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
   min-height: 3em;
   margin: 1em auto;
}

.plugin-finder-text-box {
   grid-column: 1 / -1;
   padding: 0 1em;
   max-width: 800px;
   margin: auto;
}

.missing-plugins-container {
   width: 100%;
   grid-column: 1 / -1;
   margin: 1em auto;
}

.missing-plugins-inner-container {
   margin: 3em auto;
   border: 1px solid;
   border-radius: 50px;
   padding: 2em;
   max-width: 800px;
   background: var(--iq-color-background);
   color: var(--iq-color-foreground);
}

.plugin-finder-container .missing-plugins-inner-container > button {
   margin: 1em auto;
   width: 80%;
   background: var(--iq-color-accent-gradient);
   color: var(--iq-color-background);
   border-radius: 50px;
}

.plugin-finder-container .missing-plugins-inner-container ul button {
   margin: auto;
   padding: 0.25em 0.5em;
   width: fit-content;
   background: var(--iq-color-accent-gradient);
   color: var(--iq-color-background);
   border-radius: 50px;
}

.missing-ignore-plugin-list-container,
.url-input-container,
.new-plugin-container,
.missing-plugins-container,
.missing-plugins-inner-container {
   position: relative;
}

.plugin-finder-container .missing-ignore-plugin-list-container button {
   margin: 1em auto;
   width: 80%;
   background: var(--iq-color-accent-2-gradient);
   color: var(--iq-color-background);
}

.modal {
   position: fixed;
}

.modal > * {
   overflow: auto;
   max-height: 100%;
}
.modal.missing-plugins-container {
   position: fixed;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 10000;
}

.modal.missing-plugins-container:before {
   content: "";
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: var(--iq-color-accent-gradient);
   opacity: 0.9;
   z-index: -1;
}

.highlighted-message {
   color: var(--iq-color-accent-2);
   background: var(--iq-color-background-contrast);
   padding: 1em 2em;
   border: 1px solid;
   border-radius: 50px;
   max-width: 500px;
   margin: auto;
}

.highlighted-minor-message {
   background: var(--iq-color-accent);
   color: var(--iq-color-background);
   padding: 0 0.5em 0.5em;
   border: 1px solid;
   border-radius: 50px;
   max-width: 500px;
   margin: auto;
}

.plugin-finder-selector-wrap
   .ignore-plugin-list-container
   ul
   li
   .ignore-list-button,
.plugin-finder-selector-wrap
   .missing-ignore-plugin-list-container
   ul
   li
   .ignore-list-button {
   position: absolute;
   top: 50%;
   width: 100%;
   height: 100%;
   left: 50%;
   transform: translate(-50%, -50%);
   opacity: 0;
   background: var(--iq-color-accent);
   color: var(--iq-color-background-contrast);
   transition: 0s all ease;
   margin: auto;
}

.plugin-finder-selector-wrap
   .missing-ignore-plugin-list-container
   ul
   li
   .ignore-list-button {
   background: var(--iq-color-accent-2);
}

.plugin-finder-selector-wrap
   .ignore-plugin-list-container
   ul
   li
   .ignore-list-button:after,
.plugin-finder-selector-wrap
   .missing-ignore-plugin-list-container
   ul
   li
   .ignore-list-button:after {
   content: "Restore";
   position: absolute;
   display: block;
   top: 50%;
   left: 50%;
   /* padding: 0.5em 0; */
   transform: translate(-50%, -50%) scale(2, 1.5);
   background: transparent;
   color: var(--iq-color-background-contrast);
   text-shadow: 0 0 3px var(--iq-color-accent);
}

body .plugin-finder-selector-wrap .ignore-plugin-list-container ul,
.ignore-list-button {
   opacity: 0.9;
}

.plugin-finder-container .plugin-finder-selector-wrap .button-container label,
.plugin-finder-container .plugin-finder-selector-wrap .button-container button {
   font-variant: all-small-caps;
   flex-grow: 1;
   min-height: 3em;
   padding: 0 2em 0.5em !important;
   display: flex;
   align-items: center;
   justify-content: center;
}

.plugin-finder-container [class*="required-input-error"] {
   border: none !important;
}

.plugin-finder-selector-wrap {
   max-width: 800px;
   width: 100%;
}

.plugin-finder-modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   max-height: 100%;
   margin: auto;
   z-index: 200;
   background: #000000f7;
   overflow: auto;
   padding-bottom: 7em;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.plugin-finder-modal input[type="submit"] {
   transform: translate(-50%, calc(-100% - 1em)) !important;
   background: var(--iq-color-accent-gradient);
   position: fixed !important;
   top: 100% !important;
   left: 50% !important;
   z-index: 10 !important;
}

.close-all-forms-button {
   width: 75%;
   min-height: 3em;
   margin: 1em;
}

.plugin-finder-container ul,
.new-plugin-container ul,
.ignore-plugin-list-container ul,
.new-plugin-container div[data-elm*="collapsible-elm"] {
   position: relative;
   max-width: 80%;
   max-height: 100%;
   list-style: none;
   /* display: flex; */
   /* flex-wrap: wrap; */
   /* column-count: 3; */
   display: grid;
   grid-template-columns: 25% 25% 25% 25%;
   width: 100%;
   padding: 0;
   min-width: 100%;
}

.ignore-plugin-list-container ul,
.missing-ignore-plugin-list-container ul {
   /* margin: 0 2em; */
}
.plugin-finder-container ul p,
.url-show-paths-button,
.login-message-wrap,
.new-plugin-container,
div[data-elm*="collapsible-elm"] {
   grid-column: 1 / -1;
}

[data-elm="missing-plugins-to-ignore-collapsible-elm"] {
   border: 1px inset;
   border-radius: 50px;
   padding: 2em;
}

.new-plugin-container {
   border: 1px solid;
   border-radius: 50px;
   padding: 3em;
   box-shadow: 3px 3px 7px -3px white inset;
   box-shadow:
      0 0 20px 0,
      inset 0 0 20px 0;
   background: var(--iq-color-foreground);
}

.url-show-paths-button {
   width: 38em;
   margin: auto;
}

.plugin-finder-container ul label[for="select-all"],
.plugin-finder-container ul h3,
.plugin-finder-container .add-to-library-button {
   display: block;
   grid-column: 1 / -1;
}
.plugin-finder-container ul label[for="select-all"],
.plugin-finder-container .add-to-library-button {
   margin: 1em;
}
.plugin-finder-container ul li,
.ignore-plugin-list-container ul li {
   position: relative;
   transition: none;
   margin: 0.25em 1em 0.25em auto;
   display: flex;
   justify-content: flex-end;
   text-align: right;
   max-width: calc(100% - 1em);
   overflow: hidden;
   white-space: nowrap;
   min-height: 1.25em;
}

.ignore-plugin-list-container ul li,
.missing-ignore-plugin-list-container ul li {
   width: 100%;
   justify-content: flex-start;
   text-align: left;
}
.plugin-finder-container ul label {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   font-size: 10px;
   flex-grow: 1;
}

/* ***** LOADER ***** */
.loader-wrap {
   position: absolute;
   top: 0;
   right: 0;
   width: 250px;
   height: 55px;
   z-index: 110;
   border-radius: 50px 0 0 50px;
   background: var(--iq-color-foreground);
   color: var(--iq-color-background-warm);
   box-shadow:
      inset 3px 3px 11px -7px,
      7px 7px 10px -7px black;
   position: absolute;
   width: 100%;
   height: 100%;
   margin: auto;
   opacity: 0.75;
}

.loader-wrap div {
   max-height: 100%;
   top: 50%;
   min-height: 100%;
   transform: translateY(-50%);
   border-radius: 0;
   position: relative;
   padding: 0;
   margin: 0 auto;
   height: 100%;
   background: transparent;
   box-shadow: none;
   overflow: visible;
}

.loader-wrap div span {
   max-height: 100%;
   height: 20px;
   top: calc(50% - 10px);
   display: flex;
   background: var(--iq-color-background-warm-gradient);
}

.plugin-finder-selector-wrap input[type="checkbox"] {
   pointer-events: none;
   position: absolute;
   visibility: hidden;
   transition: 0.1s all ease;
}

.plugin-finder-selector-wrap
   li:has(input[type="checkbox"]:checked)
   .ignore-list-button {
   display: none;
}

.plugin-finder-selector-wrap li:has(input[type="checkbox"]:checked) {
   background: var(--iq-color-foreground);
   color: var(--iq-color-accent);
   box-shadow:
      3px 3px 7px -4px var(--iq-color-accent) inset,
      3px 3px 7px -4px black inset;
   padding: 0 1em;
   border-radius: 6.125px;
   transform: scale(1) translateX(0);
   display: inline-block;
}

.plugin-finder-selector-wrap label:has(input[type="checkbox"]:checked) {
   /* transform: scale(1, 1.125) translateX(-1em); */
   font-weight: 700;
   white-space: nowrap;
   text-overflow: ellipsis;
   text-align: center;
   width: 100%;
   display: inline-block;
   text-align: right;
   overflow: hidden;
}

.plugin-finder-container .add-to-library-button {
   height: 2em;
   width: 100%;
   overflow: hidden;
   margin: 0 auto;
   padding: 0;
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
   transform: scale(1, 0);
   transition: 0.3s all ease;
   margin-bottom: 1em;
   transform-origin: bottom;
   opacity: 0;
   pointer-events: none;
}

.plugin-finder-container input[type="radio"]:checked {
   color: var(--iq-color-accent);
   background: var(--iq-color-accent);
}

.plugin-finder-container input[type="radio"] {
   box-shadow: none;
   appearance: none;
   padding: 0.5em;
   background: #005570;
}

.plugin-finder-container:has(input[type="checkbox"]:checked)
   .add-to-library-button {
   transform: scale(1);
   box-shadow:
      3px 3px 7px -4px #ffffffc9 inset,
      -3px -3px 7px -4px black inset;
   margin-top: 1em;
   opacity: 1;
   pointer-events: all;
}

.plugin-finder-container .add-to-library-text-box {
   height: 0;
}

.plugin-finder-container:has(input[type="checkbox"]:checked)
   .add-to-library-text-box {
   display: none;
}
.button-container label[for="select-none"],
.button-container label[for="select-all"] {
   width: 0;
   height: 0;
   transition: 0.3s all ease;
   padding: 0;
   flex-basis: 100%;
   margin: 1em;
}

.plugin-finder-container:has(input[type="checkbox"]:checked)
   [for="select-none"] {
   width: max-content;
   height: max-content;
   transition: 0.3s all ease;
   padding: 0 1em;
}

.ignore-plugin-list-container {
   width: 100%;
   grid-column: 1 / -1;
   margin: 1em auto 3em;
}

/* .ignore-plugin-list-container ul {
   display: flex;
   flex-wrap: wrap;
}
.ignore-plugin-list-container ul > li {
   flex-basis: 50%;
} */

.need-desktop-version-container {
   margin: 0 auto 4em;
   box-shadow: var(--apo-boxshadow-inner);
   border-radius: 50px;
   padding: 2em 1em;
   max-width: 800px;
   background: var(--iq-color-foreground-gradient);
   border: 3px inset var(--iq-color-alert-red);
   width: 100%;
   grid-column: 1 / -1;
   text-align: left;
   padding-left: 2em;
}

/* Hover turned Off for touch and stylus */
body[class*="can-hover"] .plugin-finder-container button:hover,
body[class*="can-hover"] .button-container label:hover {
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
}
body[class*="can-hover"] .plugin-finder-container .ignore-list-button:hover {
   transform: scale(2, 1.5);
   z-index: 10;
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
}
body[class*="can-hover"]
   .plugin-finder-container
   .find-new-plugins-button.open:hover {
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
}
body[class*="can-hover"] li:hover .ignore-list-button,
li:hover .ignore-list-button {
   opacity: 0.9;
}
body[class*="can-hover"] .plugin-finder-container button:hover {
   transform: scale(1, 1.125);
}

body[class*="can-hover"] .plugin-finder-container .ignore-list-button:hover {
   transform: scale(1.125) translateX(-3.125%);
   /* border-radius: 0; */
   width: 2em;
   background: var(--iq-color-accent-2);
   color: var(--iq-color-background);
}

.plugin-finder-container .ignore-plugin-list-container .ignore-list-button,
body[class*="can-hover"]
   .plugin-finder-container
   .ignore-plugin-list-container
   .ignore-list-button:hover,
.plugin-finder-container
   .missing-ignore-plugin-list-container
   .ignore-list-button,
body[class*="can-hover"]
   .plugin-finder-container
   .missing-ignore-plugin-list-container
   .ignore-list-button:hover {
   transform: none;
   top: 0;
   left: 0;
   width: 100%;
   /* background: inherit; */
   box-shadow: none;
}

body[class*="can-hover"]
   .plugin-finder-container
   div[data-elm="missing-plugins-collapsible-elm"]
   .ignore-list-button:hover {
   width: fit-content;
}
body[class*="can-hover"]
   .plugin-finder-container
   .missing-ignore-plugin-list-container
   ul
   li:hover {
   background: var(--iq-color-accent-2);
   box-shadow: none;
}

body[class*="can-hover"] .plugin-finder-container ul li:hover {
   background: var(--iq-color-foreground);
   color: var(--iq-color-accent);
   box-shadow:
      3px 3px 7px -4px var(--iq-color-accent) inset,
      3px 3px 7px -4px black inset;
   padding: 0 1em;
   border-radius: 6.125px;
   transform: scale(1) translateX(-1em);
   display: flex;
}

body[class*="can-hover"]
   .plugin-finder-container
   .missing-plugins-container
   ul
   li:hover {
   background: inherit;
   color: inherit;
   box-shadow: none;
   padding: 0;
   border-radius: 0;
   transform: none;
   display: flex;
   font-weight: 500;
}

body[class*="can-hover"]
   .plugin-finder-selector-wrap
   .missing-ignore-plugin-list-container
   li:has(.ignore-list-button:hover),
body[class*="can-hover"]
   .plugin-finder-selector-wrap
   .ignore-plugin-list-container
   li:has(.ignore-list-button:hover) {
   box-shadow: none;
   width: 125%;
   text-align: center;
}

body[class*="can-hover"] .plugin-finder-container ul li:hover {
   transform: scale(1.125) translateX(0.5em);
   font-weight: 700;
   overflow: visible;
}

body[class*="can-hover"] .plugin-finder-container ul li:hover label {
   white-space: normal;
}

.missing-ignore-plugin-list-container ul label,
.ignore-plugin-list-container ul label {
   /* text-align: center; */
   width: 100%;
}

body[class*="can-hover"]
   .plugin-finder-container
   .missing-ignore-plugin-list-container
   ul
   li:hover {
   transform: none;
   font-weight: 700;
   padding: 0 1em;
}

body[class*="can-hover"] .ignore-plugin-list-container ul li:hover,
body[class*="can-hover"] .missing-ignore-plugin-list-container ul li:hover {
   transform: none;
   font-weight: 500;
}

@media screen and (max-width: 1024px) {
   .plugin-finder-container ul,
   .ignore-plugin-list-container ul,
   .new-plugin-container div[data-elm*="collapsible-elm"] {
      grid-template-columns: 33.33% 33.33% 33.33%;
   }
}

@media screen and (max-width: 850px) {
   .plugin-finder-container ul,
   .ignore-plugin-list-container ul,
   .new-plugin-container div[data-elm*="collapsible-elm"] {
      grid-template-columns: 50% 50%;
   }
}

@media screen and (max-width: 400px) {
   .plugin-finder-container ul,
   .ignore-plugin-list-container ul,
   .new-plugin-container div[data-elm*="collapsible-elm"] {
      grid-template-columns: 100%;
   }
}
