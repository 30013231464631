.login-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 2em;
}
.login-container h3 {
   padding: 0;
   margin-bottom: 0;
   font: var(--iq-font-subheading);
}

.form {
   display: flex;
   position: relative;
   flex-wrap: wrap;
   justify-content: center;
}

.login-container .form input {
   background: inherit;
   color: inherit;
   box-shadow:
      3px 3px 7px -4px #000000 inset,
      -3px -3px 7px -4px #ffffff inset;
   height: 2em;
}

.form input[type="text"],
.form input[type="password"],
.form input[type="email"],
.form button {
   font-size: 1em;
   border-radius: 50px;
   padding: 0px 2em;
   margin: 0;
}

.form label {
   margin: 0;
   line-height: 1em;
   padding: 0;
   justify-content: inherit;
   display: none;
}

.form > div > div {
   padding: 0;
   margin: 0 auto;
   /* height: 2em; */
}

.form button {
   height: 2em;
}

.login-form-wrap {
   display: flex;
   flex-direction: column;
   position: relative;
   align-items: center;
   margin: 1em;
}
.form-submit-button-wrap {
   display: flex;
   align-items: end;
}

.form-submit-button-wrap button {
   margin: 0 auto 10px;
}
.login-in-link {
   margin-left: 1em;
}

.login-question {
   margin-top: 1em;
}
.login-question button {
   margin-left: 1em;
   width: auto;
}

.form-input-error {
   position: relative;
   padding: 0.5em 1em;
   max-width: fit-content;
   border: 4px inset var(--iq-color-accent);
   box-shadow:
      3px 3px 10px -7px inset black,
      -3px -3px 10px -7px inset white;
   border-radius: 10px;
}

.form-input-error .form-input-error-close-button {
   position: absolute;
   top: -0.25em;
   right: 0.25em;
   font-weight: 700;
   margin: 0 auto;
   width: 1em;
   padding: 0;
   background: none;
   color: inherit;
   border: none;
   box-shadow: none;
   text-shadow: 0 0 3px var(--iq-color-accent);
}

.horizontal-display .form-input-error .form-input-error-close-button {
   position: absolute;
   left: 0;
   font-size: 18px;
   font-weight: 700;
}

.form-input-error p {
   /* font-size: 2em; */
}

/* The placeholders available for HOC's that want to expose it */
-webkit-input-placeholder {
   /* WebKit browsers */
   color: transparent;
}

/* HORIZONTAL DISPLAY */
.login-container.horizontal-display {
   display: flex;
   flex-direction: row;
   margin: 0 auto;
   padding: 0;
   max-width: max-content;
   align-items: center;
}

.login-container.horizontal-display .login-question,
.login-container.horizontal-display .button-container,
.login-container.horizontal-display .login-form-wrap,
.login-container.horizontal-display .form {
   display: flex;
   flex-direction: row;
   height: 100%;
   padding: 0;
   margin: 0;
   font: var(--iq-font-body-text);
   max-width: fit-content;
   font-size: 1em;
   flex-wrap: nowrap;
}

.login-container.horizontal-display .login-question {
   margin: 0 7%;
}

.login-container.horizontal-display .form {
   margin: 0 auto;
   max-height: 100%;
}
.login-container.horizontal-display label {
   display: none;
}

.login-container.horizontal-display .form input,
.login-container.horizontal-display .form div {
   align-items: normal;
   font-size: 1em;
   padding: 0;
   margin: 0 auto;
   height: 30px;
}
.login-container.horizontal-display .form input {
   margin: 0.25em 0;
   box-shadow:
      inset 2px 2px 3px -2px black,
      inset -2px -2px 3px -2px white;
   border: 1px inset #cecece;
}

.horizontal-display .form-submit-button-wrap button,
.horizontal-display .login-question button {
   width: 13em;
   font-size: 1em;
   margin: 0;
   padding: 0;
   min-width: 8em;
   min-height: 100%;
   margin: 0;
   padding: 0;
   margin-top: auto;
   height: 30px;
}
.horizontal-display .login-question {
   text-align: right;
}

.horizontal-display .login-question button {
   background: var(--iq-color-accent-gradient);
   /* border-radius: 15px; */
   color: var(--iq-color-foreground);
   text-shadow: 0 0 3px wheat;
   background: linear-gradient(
      var(--iq-color-accent) 37%,
      rgba(0, 0, 0, 1) 100%
   );
   margin-left: 1em;
   margin-top: auto;
   height: 30px;
}

.horizontal-display ::placeholder {
   display: block !important;
   opacity: 1;
   color: #7f7f7f;
   text-align: center;
}

.horizontal-display .form-input-error {
   position: absolute;
   top: calc(100% + 1em);
   max-width: 100%;
   min-width: 0;
   padding: 0.5em 3em 1em !important;
   background: var(--iq-color-foreground-gradient);
   color: var(--iq-color-background-warm);
   left: 50%;
   transform: translateX(-50%);
   border-radius: 0 0 25px 25px;
   width: max-content;
}

.form > *,
.input-container {
   flex-grow: 1;
}

@media only screen and (max-width: 768px) {
   .form {
      max-width: 100%;
      /* overflow: hidden; */
   }
}

@media only screen and (max-width: 500px) {
   .form *,
   form .input-container {
      min-width: 100%;
      margin: 0.5em auto;
   }
}
